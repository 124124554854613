export enum LANGUAGES {
  PT_PR = 'pt-br',
  EN = 'en',
  FR = 'fr',
  ES = 'es'
}

export const TOPIFICATION_ROUTER: Record<LANGUAGES, string> = {
  [LANGUAGES.PT_PR]: 'categorias',
  [LANGUAGES.EN]: 'categories',
  [LANGUAGES.FR]: 'categories',
  [LANGUAGES.ES]: 'categorias'
};

export enum TOPIFICATION_KEY {
  ACADEMIC_STUDIES = 'ensino-e-estudo-academico',
  ANIMALS_AND_PETS = 'animais-e--pets',
  CAREER_PERSONAL_DEVELOPMENT = 'carreira-e-desenvolvimento-pessoal',
  CHILDHOOD_AND_FAMILY = 'educacao-infantil-e-familia',
  COOKING_AND_GASTRONOMY = 'culinaria-e-gastronomia',
  DESIGN_AND_PHOTOGRAPHY = 'design-e-fotografia',
  ENGINEERING_ARCHITECTURE = 'engenharia-e-arquitetura',
  FASHION_AND_BEAUTY = 'moda-e-beleza',
  FINANCE_AND_BUSINESS = 'financas-e-negocios',
  HEALTH_AND_SPORTS = 'saude-e-esportes',
  HOBBY_AND_LEISURE = 'hobbies-e-lazer',
  MAINTENANCE_AND_REPAIR = 'manutencao-de-equipamentos',
  MARKETING_AND_SALES = 'marketing-e-vendas',
  MUSIC_AND_ARTS = 'musica-e-artes',
  PLANTS_AND_ECOLOGY = 'plantas-e-ecologia',
  RELATIONSHIPS = 'relacionamentos',
  SELF_KNOWLEDGE = 'autoconhecimento-e-espiritualidade',
  SPARKLE = 'sparkle',
  TECHNOLOGY = 'tecnologia-e-desenvolvimento-de-software',
  UNMAPPING_FIELD = 'unmapping_field'
}

export enum TOPIC_KEY {
  PET = 'pet',
  ASTROLOGY = 'astrologia',
  SPIRITUALITY = 'espiritualidade',
  MEDITATION = 'meditacao',
  THEOLOGY = 'teologia',
  CARRER = 'carreira',
  COACHING = 'coaching',
  PERSONAL_DEVELOPMENT = 'desenvolvimento-pessoal',
  ORATORY = 'oratoria',
  CONFECTIONERY = 'confeitaria',
  DRINKS = 'drinks',
  GASTRONOMY = 'gastronomia',
  RECIPE = 'receitas',
  DESIGN = 'design',
  PHOTOGRAPHY = 'fotografia',
  MATERNITY = 'maternidade',
  ARCHITECTURE = 'arquitetura',
  WORKPLACE_SAFETY = 'seguranca-do-trabalho',
  EXAM = 'concurso',
  LAW = 'direito',
  EDUCATION = 'educacao',
  ENEM = 'enem',
  IDIOM = 'idiomas',
  LIBRAS = 'libras',
  LITERATURE = 'literatura',
  POLITICS = 'politica',
  PSYCHOLOGY = 'psicologia',
  ACCOUNTING = 'contabilidade',
  ENTREPRENEURSHIP = 'empreendedorismo',
  FINANCE = 'financas',
  INVESTIMENTS = 'investimentos',
  TURISMO = 'turismo',
  MAINTENANCE = 'manutencao',
  DIGITAL_MARKETING = 'marketing-digital',
  HAIR = 'cabelo',
  ESTHETICS = 'estetica',
  MANICURE = 'manicure',
  MAKEUP = 'maquiagem',
  FASHION = 'moda',
  HANDICRAFT = 'artesanato',
  DRAWING = 'desenho',
  MUSIC = 'musica',
  AGRICULTURE = 'agricultura',
  PLANTS = 'plantas',
  RELATIONSHIP = 'relacionamento',
  SLIMMING = 'emagrecimento',
  SPORTS = 'esporte',
  PHYSIOTHERAPY = 'fisioterapia',
  HEALTHY = 'saude',
  EXCEL = 'excel',
  PROGRAMMING = 'programacao',
  SITE = 'site'
}

export const topicPerTopification: Record<TOPIFICATION_KEY, TOPIC_KEY[]> = {
  [TOPIFICATION_KEY.ANIMALS_AND_PETS]: [ TOPIC_KEY.PET ],
  [TOPIFICATION_KEY.SELF_KNOWLEDGE]: [ TOPIC_KEY.ASTROLOGY, TOPIC_KEY.SPIRITUALITY, TOPIC_KEY.MEDITATION, TOPIC_KEY.THEOLOGY ],
  [TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT]: [ TOPIC_KEY.CARRER, TOPIC_KEY.COACHING, TOPIC_KEY.PERSONAL_DEVELOPMENT, TOPIC_KEY.ORATORY ],
  [TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]: [ TOPIC_KEY.CONFECTIONERY, TOPIC_KEY.DRINKS, TOPIC_KEY.GASTRONOMY, TOPIC_KEY.RECIPE ],
  [TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]: [ TOPIC_KEY.DESIGN, TOPIC_KEY.PHOTOGRAPHY ],
  [TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]: [ TOPIC_KEY.MATERNITY ],
  [TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]: [ TOPIC_KEY.ARCHITECTURE, TOPIC_KEY.WORKPLACE_SAFETY ],
  [TOPIFICATION_KEY.ACADEMIC_STUDIES]: [
    TOPIC_KEY.EXAM,
    TOPIC_KEY.LAW,
    TOPIC_KEY.EDUCATION,
    TOPIC_KEY.ENEM,
    TOPIC_KEY.IDIOM,
    TOPIC_KEY.LIBRAS,
    TOPIC_KEY.LITERATURE,
    TOPIC_KEY.POLITICS,
    TOPIC_KEY.PSYCHOLOGY
  ],
  [TOPIFICATION_KEY.FINANCE_AND_BUSINESS]: [ TOPIC_KEY.ACCOUNTING, TOPIC_KEY.ENTREPRENEURSHIP, TOPIC_KEY.FINANCE, TOPIC_KEY.INVESTIMENTS ],
  [TOPIFICATION_KEY.HOBBY_AND_LEISURE]: [ TOPIC_KEY.TURISMO ],
  [TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]: [ TOPIC_KEY.MAINTENANCE ],
  [TOPIFICATION_KEY.MARKETING_AND_SALES]: [ TOPIC_KEY.DIGITAL_MARKETING ],
  [TOPIFICATION_KEY.FASHION_AND_BEAUTY]: [ TOPIC_KEY.HAIR, TOPIC_KEY.ESTHETICS, TOPIC_KEY.MANICURE, TOPIC_KEY.MAKEUP, TOPIC_KEY.FASHION ],
  [TOPIFICATION_KEY.MUSIC_AND_ARTS]: [ TOPIC_KEY.HANDICRAFT, TOPIC_KEY.DRAWING, TOPIC_KEY.MUSIC ],
  [TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]: [ TOPIC_KEY.AGRICULTURE, TOPIC_KEY.PLANTS ],
  [TOPIFICATION_KEY.RELATIONSHIPS]: [ TOPIC_KEY.RELATIONSHIP ],
  [TOPIFICATION_KEY.HEALTH_AND_SPORTS]: [ TOPIC_KEY.SLIMMING, TOPIC_KEY.SPORTS, TOPIC_KEY.PHYSIOTHERAPY, TOPIC_KEY.HEALTHY ],
  [TOPIFICATION_KEY.SPARKLE]: [],
  [TOPIFICATION_KEY.TECHNOLOGY]: [ TOPIC_KEY.EXCEL, TOPIC_KEY.PROGRAMMING, TOPIC_KEY.SITE ],
  [TOPIFICATION_KEY.UNMAPPING_FIELD]: []
};

export const topificationList = Object.keys(topicPerTopification) as TOPIFICATION_KEY[];
