import { LANGUAGES, TOPIC_KEY, TOPIFICATION_KEY } from './topification';

// TODO - criar uma função que gere os slugs a partir dos arquivos de tradução
export const TOPIFICATION_TRANSLATION: Record<LANGUAGES, Record<TOPIFICATION_KEY, string>> = {
  [LANGUAGES.PT_PR]: {
    [TOPIFICATION_KEY.ACADEMIC_STUDIES]: 'ensino-e-estudo-academico',
    [TOPIFICATION_KEY.ANIMALS_AND_PETS]: 'animais-e-pets',
    [TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT]: 'carreira-e-desenvolvimento-pessoal',
    [TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]: 'educacao-infantil-e-familia',
    [TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]: 'culinaria-e-gastronomia',
    [TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]: 'design-e-fotografia',
    [TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]: 'engenharia-e-arquitetura',
    [TOPIFICATION_KEY.FASHION_AND_BEAUTY]: 'moda-e-beleza',
    [TOPIFICATION_KEY.FINANCE_AND_BUSINESS]: 'financas-e-negocios',
    [TOPIFICATION_KEY.HEALTH_AND_SPORTS]: 'saude-e-esportes',
    [TOPIFICATION_KEY.HOBBY_AND_LEISURE]: 'tempo-livre-e-lazer',
    [TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]: 'manutencao-de-equipamentos',
    [TOPIFICATION_KEY.MARKETING_AND_SALES]: 'marketing-e-vendas',
    [TOPIFICATION_KEY.MUSIC_AND_ARTS]: 'musica-e-artes',
    [TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]: 'plantas-e-ecologia',
    [TOPIFICATION_KEY.RELATIONSHIPS]: 'relacionamentos',
    [TOPIFICATION_KEY.SELF_KNOWLEDGE]: 'autoconhecimento-e-espiritualidade',
    [TOPIFICATION_KEY.SPARKLE]: 'sparkle',
    [TOPIFICATION_KEY.TECHNOLOGY]: 'tecnologia-e-desenvolvimento-de-software',
    [TOPIFICATION_KEY.UNMAPPING_FIELD]: 'sem-categoria'
  },
  [LANGUAGES.EN]: {
    [TOPIFICATION_KEY.ACADEMIC_STUDIES]: 'academic-studies',
    [TOPIFICATION_KEY.ANIMALS_AND_PETS]: 'animals-and-pets',
    [TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT]: 'career-and-personal-development',
    [TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]: 'childhood-and-family',
    [TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]: 'cooking-and-gastronomy',
    [TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]: 'design-and-photography',
    [TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]: 'engineering-and-architecture',
    [TOPIFICATION_KEY.FASHION_AND_BEAUTY]: 'fashion-and-beauty',
    [TOPIFICATION_KEY.FINANCE_AND_BUSINESS]: 'finance-and-business',
    [TOPIFICATION_KEY.HEALTH_AND_SPORTS]: 'health-and-sports',
    [TOPIFICATION_KEY.HOBBY_AND_LEISURE]: 'hobby-and-leisure',
    [TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]: 'maintenance-and-repair',
    [TOPIFICATION_KEY.MARKETING_AND_SALES]: 'marketing-and-sales',
    [TOPIFICATION_KEY.MUSIC_AND_ARTS]: 'music-and-arts',
    [TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]: 'plants-and-ecology',
    [TOPIFICATION_KEY.RELATIONSHIPS]: 'relationships',
    [TOPIFICATION_KEY.SELF_KNOWLEDGE]: 'self-knowledge-and-spirituality',
    [TOPIFICATION_KEY.SPARKLE]: 'sparkle',
    [TOPIFICATION_KEY.TECHNOLOGY]: 'technology-and-software-development',
    [TOPIFICATION_KEY.UNMAPPING_FIELD]: 'unmapping-fields'
  },
  [LANGUAGES.ES]: {
    [TOPIFICATION_KEY.ACADEMIC_STUDIES]: 'ensino-e-estudo-academico',
    [TOPIFICATION_KEY.ANIMALS_AND_PETS]: 'animais-e-animais-de-estimacao',
    [TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT]: 'desarrollo-personal',
    [TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]: 'educacao-infantil-e-familia',
    [TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]: 'culinaria-y-gastronomia',
    [TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]: 'design-e-fotografia',
    [TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]: 'engenharia-e-arquitetura',
    [TOPIFICATION_KEY.FASHION_AND_BEAUTY]: 'moda-y-belleza',
    [TOPIFICATION_KEY.FINANCE_AND_BUSINESS]: 'financas-e-negocios',
    [TOPIFICATION_KEY.HEALTH_AND_SPORTS]: 'saude-e-esportes',
    [TOPIFICATION_KEY.HOBBY_AND_LEISURE]: 'tempos-livres-e-lazer',
    [TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]: 'manutencao-de-equipamentos',
    [TOPIFICATION_KEY.MARKETING_AND_SALES]: 'marketing-e-vendas',
    [TOPIFICATION_KEY.MUSIC_AND_ARTS]: 'musica-y-artes',
    [TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]: 'plantas-y-ecologia',
    [TOPIFICATION_KEY.RELATIONSHIPS]: 'relaciones',
    [TOPIFICATION_KEY.SELF_KNOWLEDGE]: 'autoconhecimento-y-espiritualidade',
    [TOPIFICATION_KEY.SPARKLE]: 'sparkle',
    [TOPIFICATION_KEY.TECHNOLOGY]: 'tecnologia-e-desenvolvimento-de-software',
    [TOPIFICATION_KEY.UNMAPPING_FIELD]: 'sin-categoria'
  },
  [LANGUAGES.FR]: {
    [TOPIFICATION_KEY.ACADEMIC_STUDIES]: 'enseignement-et-etudes-academiques',
    [TOPIFICATION_KEY.ANIMALS_AND_PETS]: 'animaux-et-animaux-de-compagnie',
    [TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT]: 'carriere-et-developpement-personnel',
    [TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]: 'enseignement-primaire-et-famille',
    [TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]: 'cuisine-et-gastronomie',
    [TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]: 'design-et-photographie',
    [TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]: 'ingenierie-et-architecture',
    [TOPIFICATION_KEY.FASHION_AND_BEAUTY]: 'mode-et-Beaute',
    [TOPIFICATION_KEY.FINANCE_AND_BUSINESS]: 'finances-et-business',
    [TOPIFICATION_KEY.HEALTH_AND_SPORTS]: 'sante-et-sport',
    [TOPIFICATION_KEY.HOBBY_AND_LEISURE]: 'temps-libre-et-loisirs',
    [TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]: 'entretien-des-equipements',
    [TOPIFICATION_KEY.MARKETING_AND_SALES]: 'marketing-et-ventes',
    [TOPIFICATION_KEY.MUSIC_AND_ARTS]: 'musique-et-arts',
    [TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]: 'plantes-et-ecologie',
    [TOPIFICATION_KEY.RELATIONSHIPS]: 'relations',
    [TOPIFICATION_KEY.SELF_KNOWLEDGE]: 'connaissance-de-soi-et-spiritualite',
    [TOPIFICATION_KEY.SPARKLE]: 'sparkle',
    [TOPIFICATION_KEY.TECHNOLOGY]: 'technologie-et-developpement-de-logiciels',
    [TOPIFICATION_KEY.UNMAPPING_FIELD]: 'sans-categorie'
  }
};

export const TOPIC_TRANSLATION: Record<LANGUAGES, Record<TOPIC_KEY, string>> = {
  [LANGUAGES.PT_PR]: {
    [TOPIC_KEY.ACCOUNTING]: 'contabilidade',
    [TOPIC_KEY.AGRICULTURE]: 'agricultura',
    [TOPIC_KEY.ARCHITECTURE]: 'arquitetura',
    [TOPIC_KEY.ASTROLOGY]: 'astrologia',
    [TOPIC_KEY.CARRER]: 'carreira',
    [TOPIC_KEY.COACHING]: 'coaching',
    [TOPIC_KEY.CONFECTIONERY]: 'confeitaria',
    [TOPIC_KEY.DESIGN]: 'design',
    [TOPIC_KEY.DIGITAL_MARKETING]: 'marketing-digital',
    [TOPIC_KEY.DRAWING]: 'desenho',
    [TOPIC_KEY.DRINKS]: 'drinks',
    [TOPIC_KEY.EDUCATION]: 'educacao',
    [TOPIC_KEY.ENEM]: 'enem',
    [TOPIC_KEY.ENTREPRENEURSHIP]: 'empreendedorismo',
    [TOPIC_KEY.ESTHETICS]: 'estetica',
    [TOPIC_KEY.EXAM]: 'concurso',
    [TOPIC_KEY.EXCEL]: 'excel',
    [TOPIC_KEY.FASHION]: 'moda',
    [TOPIC_KEY.FINANCE]: 'financas',
    [TOPIC_KEY.GASTRONOMY]: 'gastronomia',
    [TOPIC_KEY.HAIR]: 'cabelo',
    [TOPIC_KEY.HANDICRAFT]: 'artesanato',
    [TOPIC_KEY.HEALTHY]: 'saude',
    [TOPIC_KEY.IDIOM]: 'idiomas',
    [TOPIC_KEY.INVESTIMENTS]: 'investimentos',
    [TOPIC_KEY.LAW]: 'direito',
    [TOPIC_KEY.LIBRAS]: 'libras',
    [TOPIC_KEY.LITERATURE]: 'literatura',
    [TOPIC_KEY.MAINTENANCE]: 'manutencao',
    [TOPIC_KEY.MAKEUP]: 'maquiagem',
    [TOPIC_KEY.MANICURE]: 'manicure',
    [TOPIC_KEY.MATERNITY]: 'maternidade',
    [TOPIC_KEY.MEDITATION]: 'meditacao',
    [TOPIC_KEY.MUSIC]: 'musica',
    [TOPIC_KEY.ORATORY]: 'oratoria',
    [TOPIC_KEY.PERSONAL_DEVELOPMENT]: 'desenvolvimento-pessoal',
    [TOPIC_KEY.PET]: 'pet',
    [TOPIC_KEY.PHOTOGRAPHY]: 'fotografia',
    [TOPIC_KEY.PHYSIOTHERAPY]: 'fisioterapia',
    [TOPIC_KEY.PLANTS]: 'plantas',
    [TOPIC_KEY.POLITICS]: 'politica',
    [TOPIC_KEY.PROGRAMMING]: 'programacao',
    [TOPIC_KEY.PSYCHOLOGY]: 'psicologia',
    [TOPIC_KEY.RECIPE]: 'receitas',
    [TOPIC_KEY.RELATIONSHIP]: 'relacionamento',
    [TOPIC_KEY.SITE]: 'site',
    [TOPIC_KEY.SLIMMING]: 'emagrecimento',
    [TOPIC_KEY.SPIRITUALITY]: 'espiritualidade',
    [TOPIC_KEY.SPORTS]: 'esporte',
    [TOPIC_KEY.THEOLOGY]: 'teologia',
    [TOPIC_KEY.TURISMO]: 'turismo',
    [TOPIC_KEY.WORKPLACE_SAFETY]: 'seguranca-do-trabalho'
  },
  [LANGUAGES.EN]: {
    [TOPIC_KEY.ACCOUNTING]: 'accounting',
    [TOPIC_KEY.AGRICULTURE]: 'agriculture',
    [TOPIC_KEY.ARCHITECTURE]: 'architecture',
    [TOPIC_KEY.ASTROLOGY]: 'astrology',
    [TOPIC_KEY.CARRER]: 'career',
    [TOPIC_KEY.COACHING]: 'coaching',
    [TOPIC_KEY.CONFECTIONERY]: 'pastry-and-confectionery',
    [TOPIC_KEY.DESIGN]: 'design',
    [TOPIC_KEY.DIGITAL_MARKETING]: 'digital-marketing',
    [TOPIC_KEY.DRAWING]: 'drawing',
    [TOPIC_KEY.DRINKS]: 'drinks',
    [TOPIC_KEY.EDUCATION]: 'education',
    [TOPIC_KEY.ENEM]: 'enem',
    [TOPIC_KEY.ENTREPRENEURSHIP]: 'entrepreneurship',
    [TOPIC_KEY.ESTHETICS]: 'beauty',
    [TOPIC_KEY.EXAM]: 'entry-exams',
    [TOPIC_KEY.EXCEL]: 'excel-document',
    [TOPIC_KEY.FASHION]: 'fashion',
    [TOPIC_KEY.FINANCE]: 'finances',
    [TOPIC_KEY.GASTRONOMY]: 'gastronomy',
    [TOPIC_KEY.HAIR]: 'hair',
    [TOPIC_KEY.HANDICRAFT]: 'crafts',
    [TOPIC_KEY.HEALTHY]: 'health',
    [TOPIC_KEY.IDIOM]: 'languages',
    [TOPIC_KEY.INVESTIMENTS]: 'investing',
    [TOPIC_KEY.LAW]: 'law',
    [TOPIC_KEY.LIBRAS]: 'brazilian-sign-language',
    [TOPIC_KEY.LITERATURE]: 'literature',
    [TOPIC_KEY.MAINTENANCE]: 'maintenance',
    [TOPIC_KEY.MAKEUP]: 'makeup',
    [TOPIC_KEY.MANICURE]: 'nail-art',
    [TOPIC_KEY.MATERNITY]: 'maternity',
    [TOPIC_KEY.MEDITATION]: 'meditation',
    [TOPIC_KEY.MUSIC]: 'music',
    [TOPIC_KEY.ORATORY]: 'public-speaking',
    [TOPIC_KEY.PERSONAL_DEVELOPMENT]: 'personal-deveopment',
    [TOPIC_KEY.PET]: 'pet',
    [TOPIC_KEY.PHOTOGRAPHY]: 'photography',
    [TOPIC_KEY.PHYSIOTHERAPY]: 'physical-therapy',
    [TOPIC_KEY.PLANTS]: 'gardening',
    [TOPIC_KEY.POLITICS]: 'politics',
    [TOPIC_KEY.PROGRAMMING]: 'programming',
    [TOPIC_KEY.PSYCHOLOGY]: 'psychology',
    [TOPIC_KEY.RECIPE]: 'recipe',
    [TOPIC_KEY.RELATIONSHIP]: 'relationship',
    [TOPIC_KEY.SITE]: 'website',
    [TOPIC_KEY.SLIMMING]: 'weight-loss',
    [TOPIC_KEY.SPIRITUALITY]: 'spirituality',
    [TOPIC_KEY.SPORTS]: 'sports',
    [TOPIC_KEY.THEOLOGY]: 'theology',
    [TOPIC_KEY.TURISMO]: 'travel',
    [TOPIC_KEY.WORKPLACE_SAFETY]: 'workplace-safety'
  },
  [LANGUAGES.ES]: {
    [TOPIC_KEY.ACCOUNTING]: 'contabilidad',
    [TOPIC_KEY.AGRICULTURE]: 'agricultura',
    [TOPIC_KEY.ARCHITECTURE]: 'arquitectura',
    [TOPIC_KEY.ASTROLOGY]: 'astrologia',
    [TOPIC_KEY.CARRER]: 'carreira',
    [TOPIC_KEY.COACHING]: 'coaching',
    [TOPIC_KEY.CONFECTIONERY]: 'repostería',
    [TOPIC_KEY.DESIGN]: 'design',
    [TOPIC_KEY.DIGITAL_MARKETING]: 'marketing-digital',
    [TOPIC_KEY.DRAWING]: 'diseno',
    [TOPIC_KEY.DRINKS]: 'bebidas',
    [TOPIC_KEY.EDUCATION]: 'educacion',
    [TOPIC_KEY.ENEM]: 'acceso-a-la-universidad',
    [TOPIC_KEY.ENTREPRENEURSHIP]: 'emprendimiento',
    [TOPIC_KEY.ESTHETICS]: 'estetica',
    [TOPIC_KEY.EXAM]: 'concurso',
    [TOPIC_KEY.EXCEL]: 'excel',
    [TOPIC_KEY.FASHION]: 'moda',
    [TOPIC_KEY.FINANCE]: 'finanzas',
    [TOPIC_KEY.GASTRONOMY]: 'gastronomia',
    [TOPIC_KEY.HAIR]: 'cabelo',
    [TOPIC_KEY.HANDICRAFT]: 'artesania',
    [TOPIC_KEY.HEALTHY]: 'salud',
    [TOPIC_KEY.IDIOM]: 'linguas',
    [TOPIC_KEY.INVESTIMENTS]: 'inversiones',
    [TOPIC_KEY.LAW]: 'derecho',
    [TOPIC_KEY.LIBRAS]: 'lingua-gestual-portuguesa',
    [TOPIC_KEY.LITERATURE]: 'literatura',
    [TOPIC_KEY.MAINTENANCE]: 'mantenimiento',
    [TOPIC_KEY.MAKEUP]: 'maquillaje',
    [TOPIC_KEY.MANICURE]: 'manicure',
    [TOPIC_KEY.MATERNITY]: 'maternidad',
    [TOPIC_KEY.MEDITATION]: 'meditar',
    [TOPIC_KEY.MUSIC]: 'musica',
    [TOPIC_KEY.ORATORY]: 'oratoria',
    [TOPIC_KEY.PERSONAL_DEVELOPMENT]: 'desarrollo-personal',
    [TOPIC_KEY.PET]: 'pet',
    [TOPIC_KEY.PHOTOGRAPHY]: 'fotografia',
    [TOPIC_KEY.PHYSIOTHERAPY]: 'fisioterapia',
    [TOPIC_KEY.PLANTS]: 'plantas',
    [TOPIC_KEY.POLITICS]: 'politica',
    [TOPIC_KEY.PROGRAMMING]: 'programacion',
    [TOPIC_KEY.PSYCHOLOGY]: 'psicologia',
    [TOPIC_KEY.RECIPE]: 'ingresos',
    [TOPIC_KEY.RELATIONSHIP]: 'relacion',
    [TOPIC_KEY.SITE]: 'site',
    [TOPIC_KEY.SLIMMING]: 'adelgazamiento',
    [TOPIC_KEY.SPIRITUALITY]: 'espiritualidad',
    [TOPIC_KEY.SPORTS]: 'desporto',
    [TOPIC_KEY.THEOLOGY]: 'teologia',
    [TOPIC_KEY.TURISMO]: 'turismo',
    [TOPIC_KEY.WORKPLACE_SAFETY]: 'seguranca-do-trabalho'
  },
  [LANGUAGES.FR]: {
    [TOPIC_KEY.ACCOUNTING]: 'comptabilite',
    [TOPIC_KEY.AGRICULTURE]: 'agriculture',
    [TOPIC_KEY.ARCHITECTURE]: 'architecture',
    [TOPIC_KEY.ASTROLOGY]: 'astrologie',
    [TOPIC_KEY.CARRER]: 'carriere',
    [TOPIC_KEY.COACHING]: 'coaching',
    [TOPIC_KEY.CONFECTIONERY]: 'confiserie',
    [TOPIC_KEY.DESIGN]: 'design',
    [TOPIC_KEY.DIGITAL_MARKETING]: 'marketing-digital',
    [TOPIC_KEY.DRAWING]: 'dessin',
    [TOPIC_KEY.DRINKS]: 'boissons',
    [TOPIC_KEY.EDUCATION]: 'education',
    [TOPIC_KEY.ENEM]: 'enem',
    [TOPIC_KEY.ENTREPRENEURSHIP]: 'entrepreneuriat',
    [TOPIC_KEY.ESTHETICS]: 'esthetique',
    [TOPIC_KEY.EXAM]: 'concours',
    [TOPIC_KEY.EXCEL]: 'excel',
    [TOPIC_KEY.FASHION]: 'mode',
    [TOPIC_KEY.FINANCE]: 'finances',
    [TOPIC_KEY.GASTRONOMY]: 'gastronomie',
    [TOPIC_KEY.HAIR]: 'cheveux',
    [TOPIC_KEY.HANDICRAFT]: 'artisanat',
    [TOPIC_KEY.HEALTHY]: 'sante',
    [TOPIC_KEY.IDIOM]: 'langues',
    [TOPIC_KEY.INVESTIMENTS]: 'investissements',
    [TOPIC_KEY.LAW]: 'droit',
    [TOPIC_KEY.LIBRAS]: 'langue-des-signes-portugaise',
    [TOPIC_KEY.LITERATURE]: 'litterature',
    [TOPIC_KEY.MAINTENANCE]: 'maintenance',
    [TOPIC_KEY.MAKEUP]: 'maquillage',
    [TOPIC_KEY.MANICURE]: 'manicure',
    [TOPIC_KEY.MATERNITY]: 'maternite',
    [TOPIC_KEY.MEDITATION]: 'meditation',
    [TOPIC_KEY.MUSIC]: 'musique',
    [TOPIC_KEY.ORATORY]: 'oratoire',
    [TOPIC_KEY.PERSONAL_DEVELOPMENT]: 'developpement-personnel',
    [TOPIC_KEY.PET]: 'animaux-de-compagnie',
    [TOPIC_KEY.PHOTOGRAPHY]: 'Photo',
    [TOPIC_KEY.PHYSIOTHERAPY]: 'physiotherapie',
    [TOPIC_KEY.PLANTS]: 'plantes',
    [TOPIC_KEY.POLITICS]: 'politique',
    [TOPIC_KEY.PROGRAMMING]: 'programme',
    [TOPIC_KEY.PSYCHOLOGY]: 'psychologie',
    [TOPIC_KEY.RECIPE]: 'Revenu',
    [TOPIC_KEY.RELATIONSHIP]: 'relation',
    [TOPIC_KEY.SITE]: 'site',
    [TOPIC_KEY.SLIMMING]: 'minceur',
    [TOPIC_KEY.SPIRITUALITY]: 'spiritualite',
    [TOPIC_KEY.SPORTS]: 'sport',
    [TOPIC_KEY.THEOLOGY]: 'theologie',
    [TOPIC_KEY.TURISMO]: 'tourisme',
    [TOPIC_KEY.WORKPLACE_SAFETY]: 'securite-au-travail'
  }
};
