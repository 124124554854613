import { envConfig } from '../../env.config';
import {
  productPageRoute,
  productQueryString
} from '../constants';
import Utils from 'global/scripts/utils';
import {
  TOPIC_KEY,
  TOPIFICATION_KEY,
  TOPIFICATION_ROUTER
} from './topification/topification';
import { TOPIC_TRANSLATION, TOPIFICATION_TRANSLATION } from './topification/translation';

export const getQueryParams = (url) => {
  let queryParams = {};
  try {
    const builtURL = url instanceof URL ? url : new URL(url);
    let queryStrings = `${builtURL.search || ''}`.substring(1);
    let params = queryStrings.split('&');

    if (params.length > 0) {
      params.map((param) => {
        const pair = param.split('=');
        if (pair && pair.length === 2) {
          queryParams[pair[0]] = decodeURIComponent(pair[1]);
        }
      });
    }
  } catch (err) {}
  return queryParams;
};

export const addParamToURL = (url: string, param: string, value: string) => {
  url = `${url + (url.match(/[\?]/g) ? '&' : '?')}${encodeURIComponent(
    param
  )}=${encodeURIComponent(value)}`;

  return url;
};

export function generateURLQueryString(url: string, qsMap?: object) {
  if (!qsMap || typeof qsMap !== 'object') return url;
  return Object.keys(qsMap).reduce(
    (u, key) => addParamToURL(u, key, qsMap[key]),
    url
  );
}

export function prepareForResultsPage(language?: string) {
  if (!language) return productPageRoute.en;
  language = language.toString().toLowerCase() as string;

  return productPageRoute[language];
}

export const getTopificationRouter = (params: {
  language: Language;
  topification: TOPIFICATION_KEY;
  topic?: TOPIC_KEY | null;
}) => {
  const verifiedLang = Utils.verifyLanguage(params.language);
  const baseUrl = `${envConfig.APP}/${verifiedLang}/marketplace/${
    TOPIFICATION_ROUTER[verifiedLang]
  }/${TOPIFICATION_TRANSLATION[verifiedLang][params.topification]}`;
  if (!params.topic) return baseUrl;

  return `${baseUrl}/${TOPIC_TRANSLATION[verifiedLang][params.topic]}`;
};

export const isOrganicSearch = (previousPath: string | undefined) => {
  if (previousPath) {
    if (isURLCampaign(previousPath)) {
      return false;
    }

    const source = extractHostURL(previousPath);
    const searchEngines = [
      'google.com',
      'bing.com',
      'r.search.yahoo.com',
      'br.search.yahoo.com',
      'duckduckgo.com',
      'ecosia.org',
      'yandex.com'
    ];

    return searchEngines.includes(source);
  }

  return false;
};

export const isOrganicOrMaketplace = (source: string, sck: string) => {
  return (
    isOrganicSearch(source) ||
    sck === productQueryString.PRODUCT_MARKETPLACE[0].value
  );
};

function isURLCampaign(url: string) {
  const regEx =
    /(utm_source|utm_medium|utm_content|utm_campaign|utm_term|utm_content)/gm;
  return regEx.test(url);
}

function extractHostURL(url: string) {
  let hostname = '';

  if (url) {
    try {
      const previousPath = new URL(url);
      hostname = previousPath?.hostname?.replace('www.', '') || hostname;
    } catch (e) {
      console.error(e);
    }
  }

  return hostname;
}

export function mountMarketplaceResultsPage(
  language: string = 'pt-br',
  query?: object
) {
  let mainUrl = `${envConfig.APP}/${Utils.verifyLanguage(
    language
  )}/marketplace/${prepareForResultsPage(language)}`;
  if (query) {
    Object.keys(query).forEach((key) => {
      if (Array.isArray(query[key])) {
        const joinedQuery = query[key].join('_-_');

        if (joinedQuery) {
          mainUrl = addParamToURL(mainUrl, key, joinedQuery);
        }
      } else {
        mainUrl = addParamToURL(mainUrl, key, query[key]);
      }
    });
  }
  return mainUrl;
}

export function mountMarketplacePage(language) {
  return `${envConfig.APP}/${language?.toLowerCase()}/marketplace`
}

export function mountLink(url: string, sck?: string, src?: string, off?: string, offDiscount?: string, draft?: string, preview?: string, xcod?: string, origin?: string, feature?: string) {
  let finalURL = url;

  if (sck) {
    finalURL = addParamToURL(finalURL, 'sck', sck);
  }

  if (src) {
    finalURL = addParamToURL(finalURL, 'src', src);
  }

  if (off) {
    finalURL = addParamToURL(finalURL, 'off', off)
  }

  if (offDiscount) {
    finalURL = addParamToURL(finalURL, 'offDiscount', offDiscount)
  }

  if (xcod) {
    finalURL = addParamToURL(finalURL, 'xcod', xcod)
  }

  if (draft) {
    finalURL = addParamToURL(finalURL, 'draft', draft)
  }

  if (preview) {
    finalURL = addParamToURL(finalURL, 'preview', preview)
  }

  if (origin) {
    finalURL = addParamToURL(finalURL, 'origin', origin)
  }

  if (feature) {
    finalURL = addParamToURL(finalURL, 'hotfeature', feature)
  }

  return finalURL;
};
